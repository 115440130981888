<template>
<div>
  <div class="containerLink mb-3 mt-3 d-flex justify-content-between align-items-center pl-3 pr-3" v-for="item in subFolder" :key="item.id" >
    <div class="m-2 flexLink" @click="$emit('openFiles', item)">
      <div>
        {{item.name}} 
      </div>
    </div>
    <div>
      <button class="btnEdit m-3" @click="$emit('editFolder', item)" v-if="profile">
        <img src="@/assets/image/iconesUpload/edit.svg" alt="edit">
      </button>

      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-arrow-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
      </svg>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['subFolder'],
  computed: {
      profile() {
        return this.$store.getters.getUser.profile === 1
      }
    },
}
</script>

<style scoped>
.containerLink {
  border: 1px solid #98C4E6;
  box-sizing: border-box;
  border-radius: 12px;
  height: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: .2s ease-in-out;
}

.containerLink:hover {
  background: #3F88C1;
}

.containerLink a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.btnEdit {
  background: none;
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  transition: .2s ease-in-out;
  outline: none;
}

.btnEdit:hover {
  border: 1px solid #2474b2;
  background: rgba(43, 145, 223, 0.788);
}

.flexLink {
  display: flex;
  flex: 1;
}


</style>